import { Notifier } from '@airbrake/browser'

window.airbrake = new Notifier({
  projectId: window.env.AIRBRAKE_PROJECT_ID,
  projectKey: window.env.AIRBRAKE_PROJECT_KEY,
  host: 'https://panel.sutty.nl'
})

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import * as Turbo from "@hotwired/turbo"
Turbo.start()

import { Application } from 'stimulus'
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

try {
  window.axe = require('axe-core/axe')
} catch(e) {}

if (window.axe) window.axe.configure({ locale: require('axe-core/locales/es.json') })

// Prevenir que Turbolinks interfiera la navegación a un anchor al
// recargar la página
document.addEventListener('turbo:load', event => {
  if (!window.axe) return

  window.axe.run().then(results => {
    results.violations.forEach(violation => {
      violation.nodes.forEach(node => {
        node.target.forEach(target => {
          document.querySelectorAll(target).forEach(element => {
            element.classList.add('inaccesible')
            element.ariaLabel = node.failureSummary
          })
        })
      })
    })
  })
})
