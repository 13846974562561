import { Controller } from 'stimulus'

import L from 'leaflet'

export default class extends Controller {
  static targets = [ 'map', 'point' ]

  connect () {
    // Al redimensionar la página recargar el mapa
    window.addEventListener('resize', event => this.map.invalidateSize())

    // Generar un punto por cada punto :P
    for (const point of this.pointTargets) {
      const coords = this.coords_from(point)

      if (!coords) continue

      const marker = this.marker(coords)

      marker._icon.classList.add(`fill-${point.dataset.color}`)
      marker._icon.dataset.element = point.id

      marker.on('click', event => {
        if (this.previousPoint) this.hide(undefined, this.previousPoint)

        const point = this.pointTargets.find(x => x.id == event.target._icon.dataset.element)

        if (!point) return

        this.show(undefined, point, event.target)
      })
    }
  }

  /*
   * Obtener las coordenadas del mapa, por defecto el centro de CABA (!)
   */
  get coords () {
    const c = this.coords_from(this.element)

    return c ? c : [-34.6109,-58.4294]
  }

  get zoom () {
    const z = parseInt(this.element.dataset.zoom)

    return isNaN(z) ? 11 : z
  }

  get map () {
    if (!this._map) {
      this._map = L.map(this.mapTarget).setView(this.coords, this.zoom)

// https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png
      L.tileLayer('https://wms.ign.gob.ar/geoserver/gwc/service/tms/1.0.0/capabaseargenmap@EPSG%3A3857@png/{z}/{x}/{y}.png', {
        maxZoom: 21,
        tms: true,
        attribution: '&copy; <a rel="noopener noreferrer" target="_blank" href="https://www.ign.gob.ar/AreaServicios/Argenmap/IntroduccionV2">IGN</a> + <a rel="noopener noreferrer" target="_blank" href="https://openstreetmap.org/copyright">OpenStreetMap</a>'
      }).addTo(this._map)
    }

    return this._map
  }

  get icon () {
    if (!this._icon) {
      this._icon = L.divIcon({
        className: 'transition',
        iconSize: [25,41],
        iconAnchor: [12.5,41],
        html: '<svg xmlns="http://www.w3.org/2000/svg" width="25" height="41" viewBox="0 0 6.615 10.848"><path fill="#00aeef" d="M3.307 10.842c0 .002.004.006.004.006s3.304-5.361 3.304-7.123C6.615 1.132 4.94.004 3.307 0 1.674.004 0 1.132 0 3.725c0 1.762 3.305 7.123 3.305 7.123l.002-.006zM2.163 3.577c0-.67.513-1.211 1.146-1.211.634 0 1.147.542 1.147 1.211 0 .67-.515 1.212-1.149 1.212-.631 0-1.144-.542-1.144-1.212z" stroke-width="13.649"/></svg>'
      })
    }

    return this._icon
  }

  /*
   * Obtener coordenadas del dataset de un elemento, si alguna no es
   * válida devuelve undefined
   *
   * @return [Array,undefined]
   */
  coords_from(element) {
    const lat = parseFloat(element.dataset.lat)
    const lng = parseFloat(element.dataset.lng)

    if (isNaN(lat) || isNaN(lng)) return undefined

    return [lat,lng]
  }

  marker (coords) {
    return L.marker(coords, { icon: this.icon }).addTo(this.map)
  }

  hide (event = undefined, point = undefined) {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }

    if (!point) point = this.find(event.target.dataset.id)
    if (!point) return

    point.hidden = true

    if (this.previousMarker) {
      this.previousMarker._icon.classList.add(`fill-${point.dataset.color}`)
      this.previousMarker._icon.classList.remove('fill-primary')
    }
  }

  show (event = undefined, point = undefined, marker = undefined) {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }

    if (!point) point = this.find(event.target.dataset.id)
    if (!point) return

    point.hidden = false
    point.style.zIndex = 1001
    this.previousPoint = point

    if (marker) {
      marker._icon.classList.add('fill-primary')
      marker._icon.classList.remove(`fill-${point.dataset.color}`)

      this.previousMarker = marker
    }

    this.map.setView(this.coords_from(point), this.map.zoom)
  }

  find (id) {
    return this.pointTargets.find(x => x.id == id)
  }
}
